.background {
    position: static;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
}

.page-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

/* Extremely small devices (phones, 600px and down) */
@media only screen and (max-width: 375px) {
    .background {
        background-image: url(../../assets/img/bg-about-us-mobile.png);
        background-size: 100% 55%;
        background-repeat: no-repeat;
        background-position: top;
    }

    .page-content {
        padding-top: 4rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 376px) {
    .background {
        background-image: url(../../assets/img/bg-about-us-mobile.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top;
    }

    .page-content {
        padding-top: 5rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .background {
        background-size: 100% 60%;
    }

    .page-content {
        padding-top: 5rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .background {
        background-image: url(../../assets/img/bg-about-us.png), url(../../assets/img/bg-about-us-bottom.png);
        background-repeat: no-repeat, no-repeat;
        background-position: top, bottom left;
        background-size: 100% 45%, 40%;
    }

    .page-content {
        padding-top: 6rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .background {
        background-size: 100% 50%, 40%;
    }

    .page-content {
        padding-top: 8rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .background {
        background-size: 100% 60%, 40%;
    }

    .page-content {
        padding-top: 10rem;
    }
}
