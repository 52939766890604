.grey-out {
  opacity: 0.5;
}

.survey-column-wrapper {
  width: 95% !important;
  margin-top: -100px !important;
}

/* For screen sizes of 768 and 820 */
@media only screen and (min-width: 768px) and (max-width: 820px) {

    .survey-column-wrapper {
        width: 95% !important;
        margin-top: 5px !important;
      }

  }

.survey-name-col {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 16px !important;
}

.survey-btn {
  width: 100px !important;
  height: 30px !important;
  color: #75c6e0 !important;
  border: solid 1px #75c6e0 !important;
  background-color: white !important;
  font-size: 12px !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-family: Lexend Deca !important;
  padding: 0rem !important;
}

#sort-icons {
  height: 10px;
  width: 10px;
}

.survey-icons {
  height: 30px;
  width: 30px;
}

.survey-cell {
  text-align: center !important;
}

.header-text button {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Lexend Deca !important;
  color: #818181 !important;
  line-height: 25px !important;
  text-align: center !important;
  text-decoration: none !important;
}

.survey-num-header {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Lexend Deca !important;
  color: #818181 !important;
  line-height: 25px !important;
  text-align: left !important;
  text-decoration: none !important;
  padding: 0.55rem;
}

.button-cell {
  width: 40px;
}

.survey-cell img {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.setup-card {
  margin-top: -75px !important;
  padding: 50px 30px 50px 30px;
  background: white;
  border-radius: 10px;
}

.bg-info {
  background-color: #869da5 !important;
}

.error-text-smaller {
  font-size: 12px !important;
  color: dimgrey;
}

.error-box-wrapper {
  margin-top: 40px !important;
}

.loading-box-wrapper {
  margin-top: 40px !important;
  text-align: center;
}

/* ############################## */
/* FOR SCREEN SIZE 767px AND ABOVE */
/* ############################## */

@media only screen and (min-width: 767px) {
  .survey-table-rows {
    border-radius: 10px !important;
    background-color: #f9f9f9 !important;
    margin-top: 10px !important;
    vertical-align: middle !important;
    padding: 10px 10px 10px 10px !important;
    height: 50px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 25px !important;
  }

  .survey-points-col {
    font-size: 14px !important;
    text-align: left !important;
  }

  .survey-list-card {
    margin-top: 50px !important;
    padding: 50px 30px 50px 30px !important;
    background: white !important;
    border-radius: 10px !important;
  }

  .desktop-sort-row {
    display: flex;
  }

  .mobile-sort-row {
    display: none !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .survey-length-col {
    padding-left: 20px !important;
    font-size: 14px !important;
    text-align: left !important;
  }
}

/* ############################## */
/* FOR SCREEN SIZE 768px AND BELOW */
/* ############################## */
@media only screen and (max-width: 500px) {
  .survey-container {
    width: 100%;
  }

  .survey-points-col {
    padding-left: 41px !important;
    font-size: 14px !important;
    text-align: left !important;
  }

  .survey-points-col img {
    width: 16px !important;
    height: 16px !important;
  }

  .survey-length-col {
    padding-left: 40px !important;
    font-size: 14px !important;
    text-align: left !important;
  }

  .survey-table-rows {
    border-radius: 5px !important;
    background-color: #f9f9f9 !important;
    margin-top: 5px !important;
    vertical-align: middle !important;
    padding: 10px 0px 5px 0px !important;
    height: 110px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 25px !important;
  }

  .survey-list-card {
    margin-top: 100px !important;
    padding: 0px 0px 0px 0px !important;
    background: white !important;
    border-radius: 10px !important;
  }

  .desktop-sort-row {
    display: none !important;
  }

  .sort-by-label {
    font-size: 12px !important;
    width: 50% !important;
    padding: 10px 0 0 0 !important;
  }

  .length-label {
    width: 20% !important;
    padding: 0 !important;
  }

  .points-label {
    width: 20% !important;
    padding: 0 !important;
  }

  .mobile-sort-row {
    display: flex !important;
    width: 100% !important;
  }

  .mobile-header-text button {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 25px !important;
    text-align: center !important;
    text-decoration: none !important;
  }

  .mobile-header-text {
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
  }

  #sort-icons {
    height: 10px;
    width: 10px;
  }
}

/* ############################## */
/* FOR SCREEN SIZE BETWEEN 767px AND 501px */
/* ############################## */
@media only screen and (max-width: 767px) and (min-width: 501px) {
  #side-bar-points-block .row {
    width: 60% !important;
    margin-top: 20px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: 400px !important;
  }

  .survey-container {
    width: 100%;
  }

  .survey-name-col {
    width: 33% !important;
  }

  .survey-points-col {
    padding-left: 16px !important;
    font-size: 14px !important;
    text-align: left !important;
    width: 33% !important;
  }

  .survey-points-col img {
    width: 16px !important;
    height: 16px !important;
  }

  .survey-length-col {
    padding-left: 15px !important;
    font-size: 14px !important;
    text-align: left !important;
    width: 33% !important;
  }

  .survey-btn-col {
    width: 100% !important;
  }

  .survey-btn.btn.btn-primary {
    width: 80% !important;
    margin-left: 10% !important;
  }

  .survey-table-rows {
    border-radius: 5px !important;
    background-color: #f9f9f9 !important;
    margin-top: 5px !important;
    vertical-align: middle !important;
    padding: 10px 0px 5px 0px !important;
    height: 110px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 25px !important;
  }

  .survey-list-card {
    margin-top: 100px !important;
    padding: 0px 0px 0px 0px !important;
    background: white !important;
    border-radius: 10px !important;
    width: 80% !important;
    margin: auto;
    min-width: 500px !important;
  }

  .desktop-sort-row {
    display: none !important;
  }

  .sort-by-label {
    font-size: 12px !important;
    width: 50% !important;
    padding: 10px 0 0 0 !important;
  }

  .length-label {
    width: 20% !important;
    padding: 0 !important;
    text-align: center;
  }

  .points-label {
    width: 20% !important;
    padding: 0 !important;
    text-align: center;
  }

  .mobile-sort-row {
    display: flex !important;
    width: 100% !important;
  }

  .mobile-header-text button {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 25px !important;
    text-align: center !important;
    text-decoration: none !important;
  }

  .mobile-header-text {
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
  }

  #sort-icons {
    height: 10px;
    width: 10px;
  }
}
