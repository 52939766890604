.icon-logout {

  background-size: 100% 100%;
  height: 25px;
  width: 25px;
}

.logout-icon {
  height: 23px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 23px;
}
