.flex-container {
    width: 85vw;
    border: 2px solid #cfcece;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.accordion-item {
    background-color: #F5F5F5 !important;
}

.accordion button {
    background-color: #F5F5F5 !important;
}

.accordion-header {
    font-family: Lexend Deca !important;
    font-weight: bolder !important;
    font-size: 10px !important;
    color: #515151;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        max-width: 85%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        max-width: 70%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        max-width: 60%;
    }
}
