.flex-container {
    display: flex;
    margin-top: 22rem;
}

.icon-row {
    display: flex;
    justify-content: center;
    width: 40vw;
}

.icon-container {
    height: 150px;
    width: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.text-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: .5rem;
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.icon-megaphone {
    height: 100px;
    width: 100px;
    align-self: center;
    background-size: contain;
    background-image: url(../../assets/img/icon-megaphone.png);
}

.icon-reward {
    height: 100px;
    width: 100px;
    align-self: center;
    background-size: contain;
    background-image: url(../../assets/img/icon-reward.png);
}

.icon-happy {
    height: 100px;
    width: 100px;
    align-self: center;
    background-size: contain;
    background-image: url(../../assets/img/icon-happy.png);
}

.icon-bullseye {
    height: 100px;
    width: 100px;
    align-self: center;
    background-size: contain;
    background-image: url(../../assets/img/icon-bullseye.png);
}

.icon-box {
    height: 100px;
    width: 100px;
    align-self: center;
    background-size: contain;
    background-image: url(../../assets/img/icon-box.png);
}

/* Extremely small devices (phones, 600px and down) */
@media only screen and (max-width: 375px) {
    .flex-container {
        margin-top: 10rem;
        margin-bottom: 2rem;
        max-width: 85%;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        margin-top: 15rem;
        margin-bottom: 2rem;
        max-width: 85%;
    }

    .icon-row {
        width: 75vw;
    }

    .icon-container {
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

    .icon-megaphone {
        height: 80px;
        width: 80px;
    }

    .icon-reward {
        height: 80px;
        width: 80px;
    }

    .icon-happy {
        height: 80px;
        width: 80px;
    }

    .icon-bullseye {
        height: 80px;
        width: 80px;
    }

    .icon-box {
        height: 80px;
        width: 80px;
    }

    .bottom-row {
        max-width: 120px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    .icon-row {
        width: 75vw;
    }

    .icon-container {
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

    .icon-megaphone {
        height: 80px;
        width: 80px;
    }

    .icon-reward {
        height: 80px;
        width: 80px;
    }

    .icon-happy {
        height: 80px;
        width: 80px;
    }

    .icon-bullseye {
        height: 80px;
        width: 80px;
    }

    .icon-box {
        height: 80px;
        width: 80px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .flex-container {
        max-width: 100%;
        margin-top: 16rem;
    }

    .icon-row {
        width: 60vw;
    }

    .icon-container {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
    }

    .icon-megaphone {
        height: 100px;
        width: 100px;
    }

    .icon-reward {
        height: 100px;
        width: 100px;
    }

    .icon-happy {
        height: 100px;
        width: 100px;
    }

    .icon-bullseye {
        height: 100px;
        width: 100px;
    }

    .icon-box {
        height: 100px;
        width: 100px;
    }

    .bottom-row {
        max-width: 150px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        margin-top: 20rem;
        max-width: 100%;
    }

    .icon-row {
        width: 60vw;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        margin-top: 25rem;
        padding-top: 5rem;
    }

    .bottom-row {
        max-width: none;
    }
}
