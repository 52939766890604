.flex-container {
    width: 85vw;
    border: 2px solid rgb(0,0,0,0.125);
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 3rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
}

.title {
    font-family: Lexend Deca !important;
    color: #515151;
    padding-left: 1rem;
    font-weight: 600;
    font-size: 26px;
    padding-top: 2rem;
    line-height: 32.5px;
}

.dropdown {
    width: 100%;
    padding: 0.25rem;
}

.dropdown-button {
    width: 100%;
    padding: 0.25rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        max-width: 85%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        display: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        display: none;
    }
}
