@media only screen and (max-width: 768px) {


    .btn-light {
      margin-left: auto !important;
    }
  
    .btn-secondary {
      margin-right: auto !important;
    }
    
    .modal-dialog .btn-light {
   
      color: #75C6E0 !important;
      border: solid 1px #75C6E0 !important;
      background-color: white !important;
      text-align: center !important;
      font-weight: 600 !important;
      font-family: Lexend Deca !important;
     
    }
  
  
    .mobile-alert {
      text-align: center !important;
    }
  
    #notice-icon-mobile {
      height: 30px!important;
      width: 30px!important;
      margin-bottom: 20px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  
    .mobile-alert p {
      text-align: center !important;
      margin: auto !important;
    }
  
    .alert-warning {
      --bs-alert-color: #fbfbfb !important;
      --bs-alert-bg: none !important;
      --bs-alert-border-color: none !important;
    }
  
    .notice-icon {
      width: 30px;
    }
  
    #notice-text {
      color: #ffffff !important;
    }
    
  
  
    .modal-content {
      background-color: #3D4951 !important;
      border-radius: 10px !important;
    }

    .modal-body {
      color: #ffffff !important;
    }
  
    .modal-header {
      border-bottom: #c6c6c6c6 1px solid !important;
    }
  
    .btn-close {
      color: #ffffff !important;
      opacity: 1 !important;
    }
  
    .modal-footer .btn-secondary {
      color: #ffffff !important;
      font-weight: 600 !important;
      font-family: Lexend Deca !important;
      background-color: lightgrey !important;
  
    }
  
    .modal-footer {
      border-top: #3D4951 1px solid !important;
    }
  
    .modal-title {
      color: #ffffff !important;
    }
  
    .mobile-text-muted {
      color: #ffffff !important;
      font-size: 12px !important;
      margin-top: 10px !important;
    }
  
  
    }