.navbar {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffffe6;
  display: flex;
  gap: 790px;
  height: 80px;
  padding: 13px 63px;
  

}

.navbar-logo-menu {
  height: 35px;
  min-width: 192.5px;
  position: relative;
}

.navbar-text {
  text-align: right;
  margin-left: 40px;
}


/* Hide the mobile-logo by default */
.mobile-logo {
  display: none;
}

/* Hide the offcanvas-mobile-menu by default */
.offcanvas-mobile-menu {
  display: none;    
}

/* Show the mobile-logo and hide the desktop-logo when the screen size is below a certain breakpoint, for example, 767px */
@media only screen and (max-width: 825px) {
  .mobile-logo {
    display: block;
  }

  .offcanvas-mobile-menu {
    display: block;    
  }

  .desktop-logo {
    display: none;
  }

  .navbar-logo-menu {
    height: 20px;
    min-width: 90px;
    position: relative;
  }

  .home-header-link {
    display: none !important;
  }

}