

  .dashboard-home-bg {

    height: 320px;
    object-fit: cover;
    z-index: -1;
    width: 100%;
  }

