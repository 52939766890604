.accordion {
    border: none;
    align-items: center;
    flex-direction: column;
    display: flex !important;
    justify-content: center;
}

.accordion-body {
    padding-left: .5rem;
}

.accordion-header {
    max-inline-size: 75vw;
}

.accordion-button {
    font-weight: 600;
    font-size: 14px;
    color: #818181 !important;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: gray;
}

.faq-accordion button.collapsed::after {
    background-image: url(../../assets/img/plus.svg) !important;
}

.faq-accordion button:not(.collapsed)::after {
    background-image: url(../../assets/img/dash.svg) !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .accordion {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .faq-accordion button.collapsed::after {
        background-image: url(../../assets/img/plus-mobile.png) !important;
    }

    .faq-accordion button:not(.collapsed)::after {
        background-image: url(../../assets/img/dash-mobile.png) !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .accordion {
        margin-bottom: 4rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .accordion {
        margin-bottom: 2rem;
    }
}
