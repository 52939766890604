.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85vw;
    background-color: #FFFFFF;
    border: 2px solid #cfcece;
    border-radius: 20px;
    font-family: Lexend Deca !important;
    padding: 30px 20px 10px 20px !important;
    margin-bottom: 2em !important;
    color: #818181;
  }
  
  .title {
      align-self: flex-start;
      padding-top: 8px;
      padding-bottom: 8px;
      color: #515151 !important;
      font-weight: bold;
      font-size: 1.5rem;
  }

  .sub-title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  .notice-arbitration {
    padding-top: 24px;
    padding-bottom: 8px;
    font-size: 14px !important;
  }
  
  .intro {
    font-size: 14px !important;
    padding-top: 20px;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .flex-container {
      padding-top: 12rem;
      max-width: 85%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .flex-container {
      padding-top: 12rem;
      max-width: 60%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .flex-container {
      padding-top: 5rem;
      max-width: 60%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .flex-container {
      padding-top: 8rem;
      max-width: 70%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .flex-container {
      max-width: 60%;
  }
}
