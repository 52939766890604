.notice-wrapper {
  padding: 3.5rem 0;
}


.dashboard-home-bg {
  background-image: url(../../assets/img/bg-header.png);
}

/* Show the mobile-logo and hide the desktop-logo when the screen size is below a certain breakpoint, for example, 767px */
@media only screen and (max-width: 767px) {
  .dashboard-home-bg {
    background-image: url(../../assets/img/bg-mobile-header.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
  }


}