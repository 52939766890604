.flex-container {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    width: 50vw;
    padding: 45px 40px 45px 40px;
}

.title {
    font-family: Lexend Deca;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #343D40;
}

.info {
    padding-left: 1rem;
}

.icon {
    background-image: url(../../assets/img/icon-star.png);
    background-repeat: no-repeat;
    max-width: 60px;
    height: 60px;
    padding-left: 1rem;
}

.bullet-list {
    list-style-type: disc;
}

.bullet-list li {
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #65777D;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        width: 90vw;
        padding: 1rem;
        margin-bottom: 4rem;
    }

    .title {
        justify-content: center;
        margin-bottom: .5rem;
        text-align: center;
    }

    .title::before {
        content: url(../../assets/img/icon-star.png);
    }

    .icon {
        display: none;
    }

    .info {
        padding: 0px;
    }

    .list-container {
        padding-left: 0px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 80vw;
        padding: 3rem;
    }

    .title {
        margin-bottom: 1rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .flex-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 70vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        margin-bottom: 2rem;
        margin-top: 4rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        margin-top: 3rem;
        margin-bottom: 6rem;
    }

    .title {
        margin-bottom: 2rem;
    }

    .icon {
        margin-top: .5rem;
    }
}
