.notifications-container {
    max-height: 200px !important; /* Adjust this value according to your desired height */
    overflow-y: auto !important;
  }


  
  .notice-nav {
    margin-top: -25px;
    width: 100% !important;
    
  }

  #notice-icon {
    height: 20px !important;
    width: 20px !important;
    
  }

  .notice-style {
    border-radius: var(--bs-border-radius-pill)!important;
  }

  .notice-style .btn {
    height: 30px !important;
    color: #75C6E0 !important;
    border: solid 1px #75C6E0 !important;
    background-color: white !important;
    font-size: 12px !important;
    text-align: center !important;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
    padding: 0px 5px 0px 5px !important;
   
  }


  /* ADJUST NOTIFICATIONS BASED ON SCREEN SIZE.. MOVE FROM IN PAGE NOTICE TO MODAL BELOW ..i.e. 767px */
@media only screen and (max-width: 768px) {


  .btn-light {
    margin-left: auto !important;
  }

  .btn-secondary {
    margin-right: auto !important;
  }
  
  .modal-dialog .btn-light {
 
    color: #75C6E0 !important;
    border: solid 1px #75C6E0 !important;
    background-color: white !important;
    text-align: center !important;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
   
  }


  .mobile-alert {
    text-align: center !important;
  }

  #notice-icon-mobile {
    height: 30px!important;
    width: 30px!important;
    margin-bottom: 20px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mobile-alert p {
    text-align: center !important;
    margin: auto !important;
  }

  .alert-warning {
    --bs-alert-color: #fbfbfb !important;
    --bs-alert-bg: none !important;
    --bs-alert-border-color: none !important;
  }

  .notice-icon {
    width: 30px;
  }

  #notice-text {
    color: #ffffff !important;
  }
  


  .modal-content {
    background-color: #3D4951 !important;
    border-radius: 10px !important;
  }

  .modal-header {
    border-bottom: #c6c6c6c6 1px solid !important;
  }

  .btn-close {
    color: #ffffff !important;
    opacity: 1 !important;
  }

  .modal-footer .btn-secondary {
    color: #ffffff !important;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
    background-color: lightgrey !important;

  }

  .modal-footer {
    border-top: #3D4951 1px solid !important;
  }

  .modal-title {
    color: #ffffff !important;
  }

  .mobile-text-muted {
    color: #ffffff !important;
    font-size: 12px !important;
    margin-top: 10px !important;
  }


  }


  .alert-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  #accept-text {
    vertical-align: top !important;
    margin-top: -5px !important;
  }

  #notice-text {
    margin-left: 10px !important;
    width: 80% !important;
    display: inline-grid !important;
  }

  .btn-light {
    border-color: dimgray !important;
  }



  .alert-enter {
    opacity: 0 !important;
    transform: translateY(0px) !important;
  }
  
  .alert-enter-active {
    opacity: 1 !important;
    transition: opacity 100ms, transform 100ms !important;
    transition-duration: 100ms, 100ms !important;
    transition-timing-function: ease, ease !important;
    transition-delay: 0s, 0s !important;
    transition-property: opacity, transform !important;
  }
  
  .alert-exit {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  
  .alert-exit-active {
    opacity: 0 !important;
    transition: opacity 100ms, transform 100ms !important;
    transition: opacity 100ms, transform 100ms !important;
    transition-duration: 100ms, 100ms !important;
    transition-timing-function: ease, ease !important;
    transition-delay: 0s, 0s !important;
    transition-property: opacity, transform !important;
  }
  

  .text-muted {

    font-size: 12px !important;
  
  }

  