#dashboard-avatar {
  display: none;
}

/* Hide the header-name-wrapper component when the screen size is below a certain breakpoint ..i.e. 767px */
@media only screen and (max-width: 820px) {
  #dashboard-greeting {
    display: none;
  }

  #dashboard-avatar {
    display: block;
    width: 175px;
    margin: auto;
    margin-top: -100px;
  }

  #dash-avail-points-row {
    width: 250px !important;
    margin-left: 0px;
  }

  #dash-points-row {
    width: 300px !important;
    text-align: center !important;
    margin: auto;
  }

  #avail-points-text {
    width: 100%;
  }
}
