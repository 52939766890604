
  
footer {
  align-items: flex-start;
  display: flex;
  background-color: #3A4A52;
  flex-direction: column;

  min-height: 248px;
  min-width: 100% !important;
  padding-right: 20px;
  padding-left: 20px;

  width: 100% !important;
  text-align: center !important;
  color: #fbfbfb !important;
  font-family: Lexend Deca,Helvetica !important;
  font-size: 12px !important;
  
}

footer p {
  text-align: center;
  margin: auto;
}

footer ul {
  text-align: center;
  margin: auto;
}

footer a {
  text-align: center;
  margin: auto;
  color: #fbfbfb !important;
  text-decoration: none !important;
}



#footer-logo {
  height: 30px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;

}

.nav-item a {
  color: white !important;
  text-decoration: none !important;
}

.ccpa {
  font-size: 14px;
  font-weight: 700;
  padding: 3px 10px 3px 10px !important;
  border: 1px solid white;
}

#ccpa_wrapper:hover {

  background-color: #cccccc !important;
}

#ccpa_wrapper {
  text-align: center;
  margin: auto;

}





/* ############################## */
/* FOR SCREEN SIZE 768px AND ABOVE */
/* ############################## */


@media only screen and (min-width: 768px) {
  .nav-item a {
    color: white !important;
    text-decoration: none !important;
  }


  

}

/* ############################## */
/* ADJUST FOOTER CONTENT FOR SCREEN SIZE 280px AND BELOW */
/* ############################## */


@media only screen and (max-width: 280px) {
  .nav-item a {
    color: white !important;
    text-decoration: none !important;
  }

  .nav {
    width: 300px !important;
    font-size: 9px !important;
    margin-left: -10px !important;
  }

  .nav-spacer {
    padding: 6px 0px 0px 0px !important;
  }

  footer p {
    font-size: 9px !important;
  }

}

/* ############################## */
/* ADJUST FOOTER CONTENT FOR SCREEN SIZE 375px AND BELOW */
/* ############################## */


@media only screen and (max-width: 374px) and (min-width: 281px) {
  .nav-item a {
    color: white !important;
    text-decoration: none !important;
  }

  .nav {
    width: 280px !important;
    font-size: 9px !important;
    margin-left: -10px !important;
  }

  .nav-spacer {
    padding: 6px 0px 0px 0px !important;
  }

  footer p {
    font-size: 9px !important;
  }

}

/* ############################## */
/* ADJUST FOOTER CONTENT FOR SCREEN SIZE 400px AND BELOW */
/* ############################## */


@media only screen and (max-width: 400px) and (min-width: 375px)  {
  .nav-item a {
    color: white !important;
    text-decoration: none !important;
  }

  .nav {
    width: 350px !important;
    font-size: 9px !important;

  }


  footer p {
    font-size: 9px !important;
  }

}


.nav-spacer {
  padding: 6px 0px 0px 0px !important;
}