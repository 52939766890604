/* Larger Devices STYLES */

/* Hide the header-name-wrapper component when the screen size is greater than a certain breakpoint ..i.e. 767px */

@media only screen and (min-width: 821px) {
    #avail-surveys-btn {
      display: none;
    }
  
    .icon-points-text-row-mobile.row {
      display: none;
    }
  
    .sub-text-mobile {
      display: none;
    }
  }

/* MOBILE STYLES */

/* Hide the header-name-wrapper component when the screen size is below a certain breakpoint ..i.e. 767px */
@media only screen and (max-width: 820px) {
    /* HIDE NON-MOBILE ROW */
    .icon-points-text-row {
        display: none !important;
    }

    #points-to-gift-text {
        text-align: center !important;
    }
    

    .text-wrapper {
        text-align: center !important;
    }

    #points-wrapper {
        width: 100% !important;
        margin: auto !important;
        text-align: center;
    }
 
    #avail-surveys-btn {
        width: 90% !important;
        max-width: 400px;
        padding: 12px 5px 12px 5px;
        margin: auto;
        margin-top: 20px !important;
        border: none;
        border-radius: 2px;
        color: #ffffff;
        background-color: #75C6E0 !important;
        font-size: 18px !important;
        font-weight: 800 !important;
        font-family: Lexend Deca !important;
        display: block !important;
    }



   

    .icon-points-text-row-mobile {
        margin-top: 10px !important;
        background-color: #E9EDEF;
        padding: 5px 5px 0px 0px;
        border-radius: 50px;
        display: flex;
        align-items: flex-start;
        margin: auto;
        max-width: 400px !important;
        

    }

    .icon-points-text-row-mobile .col {
        flex: 0 0;
    }

    .icon-points-text-row-mobile img {
        width: 60px !important;
        height: 60px !important;
    }

    .mobile-icon-col.col {
        width: 25% !important;
        padding: 0px !important;
     
    }

    .mobile-survey-count-col.col {
        padding-top: 10px !important;
        text-align: left  !important;
        /* Change flex: 0% to flex-grow: 1 */
      
        flex-shrink: 0;

     
        font-size: 28px !important;
        font-weight: 600 !important;
        font-family: Lexend Deca !important;
        color: #818181 !important;
    }


    .mobile-text-col.col {
        font-size: 14px !important;
        font-weight: 400 !important;
        font-family: Lexend Deca !important;
        color: #818181 !important;
        padding-top: 10px !important;
        padding: 5px 5px;
        padding-bottom: 0px;
    }

    .mobile-text-col {
        white-space: nowrap;
        
        
      }

   

   



    .sub-text-mobile {
        font-size: 14px !important;
        text-align: center  !important;
        margin-top: 8px  !important;
        font-weight: 400 !important;
        font-family: Lexend Deca !important;
        color: #B1B1B1 !important;
    }



   


  }

/* END OF MOBILE STYLES */
   




h1 {
    font-size: 38px !important;
    font-weight: 700 !important;
    font-family: Lexend Deca !important;
    color: #515151 !important;
    line-height: 47.5px !important;
}

#avail-points-text {
    font-size: 24px !important;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 30px !important;
    width: 100%;
 
}

.icon-col {
    width:10% !important;
}

.left-menu-link-col {
    min-width:85% !important;
}

#dash-points-row {
    margin-top: 0px;

}

#gold-icon {
    width: 30px !important;
    margin-top: -10px !important;

}



h4 {
    font-size: 26px !important;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
    color: #515151 !important;
    line-height: 32.5px !important;
}

#all-surveys {
    vertical-align: top !important;
    margin-bottom: 30px;
    text-align: left;
}

.sub-text {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #B1B1B1 !important;
    line-height: 18px !important;

}

.col-sm-2 {
    flex: 0 0 auto;
    width: 11% !important;
}

.col-3 {
    min-width: 100px !important;
}


  
.progress-bar {
  background-color: #75C6E0 !important;
  
  }

.side-bar-totals {
    margin-bottom: 0px !important;
    font-size: 25px;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
}

.side-bar-small-header {
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 20px !important;
}




.menu-card a {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Lexend Deca !important;
    color: #515151 !important;
    line-height: 20px !important;  
    text-decoration: none !important;;  
}

.text-height p {
    margin:0px !important;
}

#side-bar-left-progress-bar {
    margin:40px 0 40px 0 !important;
    height: 30px !important;
    border-radius: 50px;
    --bs-progress-bar-color: #fff !important;
    --bs-progress-bg: #fff;
   
}

#gift-card-text {
    color: #75C6E0;
    font-weight: bold;
}

.points-to-gift-text {
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: Lexend Deca !important;
    color: #818181 !important;
    line-height: 25px !important;
    margin-top: 10px !important;

}

.side-bar-points-icons {
    width: 80px;
    height: 80px;
}


.icon-points-text-row {
    margin-top: 30px !important;
}

.menu-icons img {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px !important;
}

.menu-card {
    margin-top: 60px !important;
    border-radius: 15px !important;
    background: #EAEAEA !important;
    border: 0px !important;
}

.menu-card hr {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    width: 100% !important;

}

.link-row {
    margin-top: 20px !important;
    width: 100% !important;
}

.link-row  p {
    font-weight: bold !important;
}