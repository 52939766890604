.accordion {
  border: none;
  align-items: center;
  flex-direction: column;
  display: flex !important;
  justify-content: center;
}

.accordion-item {
  /* background-color: #F5F5F5 !important; */
  width: -webkit-fill-available;
  margin: 10px 3% 1px 3%;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  overflow: hidden;
}

.accordion-header {
  max-inline-size: 75vw;
}

.accordion-button {
  /* background-color: #F5F5F5 !important; */
  font-weight: 600;
  font-size: 14px;
  color: #515151 !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: gray;
}

/* .accordion-button.collapsed::after {
  background-image: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: none !important;
} */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .accordion {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .accordion {
    margin-bottom: 4rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .accordion {
    margin-bottom: 2rem;
  }
}
