.flex-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.title {
    font-family: Lexend Deca;
    font-size: 45px;
    font-weight: 900;
    line-height: 49px;
    color: #343D40;
}

.title>p {
    padding-left: 0px;
}

.separator {
    background-image: url(../../assets/img/about-us-line.png);
    width: 244px;
    height: 2px;
}

.body>p {
    padding-left: 0px;
}

.join-button {
    background-color: #75C6E0;
    border: 1px solid #75C6E0;
    font-family: Lexend Deca;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        width: 80vw;
        align-self: center;
        align-items: center;
    }

    .body {
        text-align: center;
        margin-bottom: 1rem;
    }

    .title {
        margin-bottom: 1rem;
    }

    .separator {
        margin-bottom: 1rem;
    }

    .join-button {
        border-radius: 50px;
        width: 10rem;
        height: 3rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        width: 80vw;
        align-self: center;
        align-items: center;
    }

    .body {
        text-align: center;
        margin-bottom: 1rem;
    }

    .title {
        margin-bottom: 1rem;
    }

    .separator {
        margin-bottom: 1rem;
    }

    .join-button {
        border-radius: 50px;
        width: 10rem;
        height: 3rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .flex-container {
        max-width: 45%;
        margin-left: 2rem;
        align-self: flex-start;
        align-items: flex-start;
    }

    .body {
        text-align: left;
    }

    .separator {
        margin-bottom: 1rem;
    }

    .join-button {
        border-radius: 50px;
        width: 10rem;
        height: 3rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        width: 45%;
        margin-left: 4rem;
    }

    .separator {
        margin-bottom: 1rem;
    }

    .join-button {
        border-radius: 50px;
        width: 10rem;
        height: 3rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        width: 646px;
    }

    .separator {
        margin-bottom: 20px;
    }

    .join-button {
        border-radius: 50px;
        width: 156px;
        height: 55px;
        margin-top: 2rem;
    }
}

/* Extremely large devices (2200px and up) */
@media only screen and (min-width: 2200px) {
    .flex-container {
        width: 646px;
        left: 280px;
    }

    .separator {
        margin-bottom: 20px;
    }

    .join-button {
        border-radius: 50px;
        width: 156px;
        height: 55px;
        margin-top: 2rem;
    }
}
