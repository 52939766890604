.flex-container {
    width: 85vw;
    border: 2px solid rgb(0,0,0,0.125);
    border-radius: 20px;
    background-color: #FFFFFF;
}

.container-header {
    font-family: Lexend Deca !important;
    color: #515151;
    padding-left: 1rem;
    font-weight: 600;
    font-size: 26px;
    padding-top: 2rem;
    line-height: 32.5px;
}

.accordion-item {
    width: -webkit-fill-available;
    margin: 0px 3% 0px 3%;
    border: none !important;
    border-radius: 10px;
    overflow: hidden;
  }

.accordion-header {
    font-family: Lexend Deca !important;
    font-weight: bolder !important;
    font-size: 10px !important;
    color: #818181;
}

.accordion > button {
    background-color: #FFFFFF !important;
}

.numbered-list {
    list-style-type: decimal;
}

.numbered-list > li::marker {
    font-weight: bold;
}

.alpha-list {
    list-style-type: lower-alpha;
}

.alpha-list > li::marker {
    font-weight: bold;
}

.bullet-list {
    list-style-type: disc;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        max-width: 85%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        max-width: 70%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        max-width: 60%;
    }
}
