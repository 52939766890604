

.setup-steps {
    text-align: center;
    margin-top: -40px !important;
} 

.setup-steps img {
    width: 35px !important;
    height: 35px !important;
    margin-top:-50px !important;
 
} 


  

  
