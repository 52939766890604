.registration-form-container {
    width: 50%;
}

.registration-response.success {
    color: green;
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
    border: 2px solid green;
    border-radius: 10px;
}

.registration-response.error {
    color: red;
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
    border: 2px solid red;
    border-radius: 10px;
}

.terms-field-wrapper {
    background: white;
    border-radius: 20px;
    padding: 20px 11px 0px 10px;
    font-size: 12px;
    margin: 0 0 20px 0 !important;
}

.reg-btn {
    width: 200px !important;
    height: 30px !important;
    color: #ffffff !important;
    font-size: 16px !important;
    border: solid 1px #75c6e0 !important;
    border-radius: 20px !important;
    background-color: #75c6e0 !important;
    font-size: 12px !important;
    text-align: center !important;
    font-weight: 600 !important;
    font-family: Lexend Deca !important;
    padding: 0rem !important;
    margin: 10px 0 0 0;
}

.password-container {
    align-items: center !important;
}

.password-icon {
    background: white !important;
    color: black !important;
    margin-bottom: 15px !important;
    position: absolute !important;
    right: 3% !important;
    z-index: 2999 !important;
}

/* Changing name to avoid conflicting alias with bootstrap */
.form-input {
    margin: 0 0 10px 0 !important;
    border-radius: 0.375rem !important;
}

/* Accesibility */

/* The sr-only class is used to hide content visually but still make it accessible to screen readers. */

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

@media only screen and ((min-width: 400px)) {
    .registration-form {
        width: 375px;
    }
}
