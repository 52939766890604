#layout-page-wrapper {
    position: relative;
    z-index: 2;
    /* max-width: 100%; */
}

#layout-page-bg {
    background-image: url(../../assets/img/bg-header.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1;
    width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #layout-page-bg {
        background-image: url(../../assets/img/bg-mobile-header.png);
        height: 20%;
        background-size: cover;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #layout-page-bg {
        background-image: url(../../assets/img/bg-mobile-header.png);
        min-height: 20%;
        background-size: cover;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    #layout-page-bg {
        background-image: url(../../assets/img/bg-header.png);
        background-size: cover;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #layout-page-bg {
        background-image: url(../../assets/img/bg-header.png);
        background-size: cover;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #layout-page-bg {
        background-image: url(../../assets/img/bg-header.png);
        height: 80%;
        background-size: contain;
    }
}
