.header-text-name {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Lexend Deca !important;
    color: #75C6E0 !important;
    line-height: 20px !important;
}

.header-text-points {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: Lexend Deca !important;
  color: #818181 !important;
  line-height: 20px !important;
}

/* Hide the header-name-wrapper component when the screen size is below a certain breakpoint ..i.e. 767px */
@media only screen and (max-width: 767px) {
  .navbar-text {
      display: none;

  }

}

