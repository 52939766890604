
body {
    margin: 0;
  }



main {
    min-height: 100vh !important;

  }



#root {
    background: #F5F5F5;
    min-height: 100vh !important;


  }

.page-wrapper {

  padding-left: 0px !important;
  padding-right: 0px !important;

}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 300px) {

  #root {

    min-width: 310px !important;

  }

}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {

  #root {

    width: 100vw !important;

  }
}

/* Small screen between (600px and 767px) */
@media only screen and (min-width: 600px) and (max-width: 767px) {

  #root {

    width: 100vw !important;

  }

}

/* Medium screen between (768px and 888px) */
@media only screen and (min-width: 768px) and (max-width: 888px) {


  .page-wrapper {
    max-width: 1400px;
    min-width: 100vw;
  }

  #root {

    min-width: 100vw;

  }
}

/* Big screen devices between (889px and 1199px) */
@media only screen and (min-width: 889px) and (max-width: 1199px) {
  .page-wrapper {
    max-width: 1199px;
    min-width: 889px;
  }

  #root {

    min-width: 889px;

  }

}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  .page-wrapper {
    max-width: 1400px;
    min-width: 1200px;
  }

  #root {

    min-width: 1200px;

  }
}
