.flex-container {
    width: 85vw;
    border: 2px solid rgb(0,0,0,0.125);
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.title {
    font-family: Lexend Deca !important;
    color: #515151;
    padding-left: .25rem;
    font-weight: 600;
    font-size: 26px;
    padding-top: 2rem;
    line-height: 32.5px;
    color: #515151;
}

form > div {
    padding: .25rem;
    margin-left: 3%;
}

.message-box {
    padding-bottom: 8rem;
    resize: none;
}

.submit-button {
    font-family: Lexend Deca !important;
    background-color: #75C6E0 !important;
    border: 2px solid #75C6E0 !important;
    border-radius: 2px;
    color: #FFFFFF !important;
    box-shadow: none !important;
    min-width: 100px;
    padding: 0.25rem;
    margin-left: 0.25rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .flex-container {
        max-width: 85%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .flex-container {
        max-width: 60%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .flex-container {
        max-width: 70%;
    }

    .form {
        width: 65%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .flex-container {
        max-width: 60%;
    }

    .form {
        width: 65%;
    }
}
