#dropdown-menu{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

/* Hide the offcanvas-mobile-menu by default */
.offcanvas-mobile-menu {
    display: none;    
  }
  
  /* Show the mobile-logo and hide the desktop-logo when the screen size is below a certain breakpoint, for example, 767px */
  @media only screen and (max-width: 820px) {

    .offcanvas-mobile-menu {
      display: block;    
    }

    #side-bar-points-block .row {
        width: 95% !important;
        margin-top: 20px !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }

    .side-bar-container {
        width:100%;
        
      }
 
  
  
  }

  @media only screen and (min-width: 821px) {



    .side-bar-container {
        padding:40px !important;
        width:40%;
        min-width:450px;
      }
    
    #side-bar-points-block {
        margin-top: 40px !important;
    }
  
  }

    .offcanvas {
        width: 80% !important;
    }

    .offcanvas-body {
    background-color: #EAEAEA !important;

    }

    .offcanvas-header {
        background-color: #EAEAEA !important;
    }

    .menu-mobile a {
        font-size: 16px !important;
        font-weight: 700 !important;
        font-family: Lexend Deca !important;
        color: #515151 !important;
        line-height: 20px !important;  
        text-decoration: none !important;;  
    }

    .text-height p {
        margin:0px !important;
    }

    #side-bar-left-progress-bar {
        margin:40px 0 40px 0 !important;
    }

    #side-bar-points-icons {
        width:80px !important;
        height: 80px !important;
    }





    .icon-text {
        margin-top: 30px !important;
    }

    .menu-icons img {
        width: 30px !important;
        height: 30px !important;
        margin-right: 10px !important;
    }


    .link-row {
        margin-top: 20px !important;
        width: 100% !important;
    }

    .link-row  p {
        font-weight: bold !important;
    }