/* Medium screen devices (820px and below) */
@media only screen and (max-width: 820px) {

    .survey-column-container {
        width: 100% !important;
    }

    .flex-container {
      display: block;
      margin: auto !important;

    }

  }

/* Extra big screen devices (821px and above) */
@media only screen and (min-width: 821px) {

    .flex-container {
      display: flex;
    }

    .survey-column-container {
      width: 60% !important;
      min-width:650px;
  }

  }
