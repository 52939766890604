/* style.module.css */

.page-not-found-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Use the full height of the viewport */
    text-align: center;
  }

  .page-not-found-content {
    /* This will help in centering the text and button vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btn-home {
    margin-top: 20px; /* Space above the button */
    /* Additional button styling if needed */
  }
